import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DisplayMediaSource } from '@shared/types';

/** Component for displaying picking screens Electron reports as available to share */
@Component({
  standalone: true,
  selector: 'app-electron-screen-picker',
  templateUrl: './electron-screen-picker.component.html',
  styleUrls: ['./electron-screen-picker.component.scss'],
  imports: [MatDialogTitle, MatDialogContent, MatDividerModule, MatButtonModule, MatIconModule],
})
export class ElectronScreenPickerComponent {
  // List of sources from main process
  @Input() sources: DisplayMediaSource[] = [];
  @Output() closeClicked = new EventEmitter<void>();
  @Output() sourceSelected = new EventEmitter<string>();

  protected onSourceSelected(sourceId: string) {
    this.sourceSelected.emit(sourceId);
  }

  protected onCloseClick() {
    this.closeClicked.emit();
  }
}
