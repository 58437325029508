import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { checkUpdate } from '@app/shared/utils/update.util';
import { ElectronChannel } from '@shared/types';
import { filter, Subscription } from 'rxjs';

import { AuthService } from './auth/services/auth.service';
import { EventData, EventName } from './core/models/event-bus.models';
import { AppTranslateService } from './core/services/app-translate.service';
import { CustomIconsService } from './core/services/custom-icons.service';
import { EventBusService } from './core/services/event-bus.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { PwaService } from './core/services/pwa.service';
import { WsService } from './core/services/ws.service';
import { ElectronService } from './electron/electron.service';
import { ElectronScreenPickerComponent } from './electron/electron-screen-picker.component';
import { PhoneService } from './phone/services/phone.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  logoutEvenSub?: Subscription;

  constructor(
    private authService: AuthService,
    private eventBusService: EventBusService,
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    protected wsService: WsService,
    private phoneService: PhoneService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private ngZone: NgZone,
    private customIconsService: CustomIconsService,
    private pwaService: PwaService,
    private appTranslateService: AppTranslateService,
    private electronService: ElectronService
  ) {
    this.appTranslateService.configure();
    this.setUpElectronScreenShareListener();
  }

  async ngOnInit() {
    this.pwaService.listenForInstallPrompt();
    this.customIconsService.registerIcons();
    if ('launchQueue' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.launchQueue.setConsumer((launchParams) => {
        if (launchParams.targetURL.toString().includes('tel')) {
          const params = launchParams.targetURL.toString().split('/');
          if (params.length > 0) {
            this.ngZone.run(() => {
              this.router.navigateByUrl('/calls/history/' + params.at(-1));
            });
          }
        }
      });
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.googleAnalyticsService.pageView(event);
    });

    // Listens for an event for when the user logouts
    // previously was in main modules ViewComponent
    this.logoutEvenSub = this.eventBusService.on(EventName.Logout, (eventData: EventData) => {
      this.wsService.disconnect();
      this.dialog.closeAll();
      this.phoneService.dispose();
      this.authService.clearAccess();

      if (eventData.message.length > 0) {
        this.authService.logoutMessage = eventData.message;
        this.router.navigate(['/logout/']);
      } else {
        const { authLogoutUrl } = this.authService.getAuthStrings();
        document.location.href = authLogoutUrl;
      }
    });

    await checkUpdate(this.swUpdate);
  }

  ngOnDestroy(): void {
    this.logoutEvenSub?.unsubscribe();
  }

  private setUpElectronScreenShareListener() {
    this.electronService.on(ElectronChannel.RequestDisplayMediaSources, (data) => {
      // Open a dialog to allow the user to select a screen
      const dialogRef = this.dialog.open(ElectronScreenPickerComponent, {
        minWidth: '50vw',
        maxWidth: '700px',
      });

      let selectedSourceId: string | null = null;

      // Set inputs and outputs here
      dialogRef.componentInstance.sources = data.sources;
      dialogRef.componentInstance.closeClicked.subscribe(() => {
        dialogRef.close();
      });
      dialogRef.componentInstance.sourceSelected.subscribe((sourceId: string) => {
        selectedSourceId = sourceId;
        this.electronService.send(ElectronChannel.DisplayMediaSourceSelected, {
          sourceId,
        });
        dialogRef.close();
        this.electronService.send(ElectronChannel.FocusWindow, { name: 'child' });
      });
      dialogRef.afterClosed().subscribe(() => {
        if (!selectedSourceId) {
          this.electronService.send(ElectronChannel.DisplayMediaSourceSelected, {
            sourceId: undefined,
          });
          this.electronService.send(ElectronChannel.FocusWindow, { name: 'child' });
        }
      });

      // After a short delay, focus the main window (the delay just makes the transition a bit less jarring)
      setTimeout(() => {
        this.electronService.send(ElectronChannel.FocusWindow, { name: 'main' });
      }, 500);
    });
  }
}
