import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenModel } from '@app/auth/models/token-model';
import { AuthService } from '@app/auth/services/auth.service';
import { EventData, EventName } from '@app/core/models/event-bus.models';
import { EventBusService } from '@app/core/services/event-bus.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private eventBusService: EventBusService
  ) {}

  public static shouldSkipAuthenticationForUrl(url: string): boolean {
    //do not skip login agent to queue or login-methods API
    if (url.includes('/agent/login')) {
      return false;
    }
    if (url.includes('/assets/i18n/')) {
      return true;
    }
    const skipUrls = [
      'login',
      'token',
      'refresh',
      'forgot-password',
      'reset-password',
      'cuc-media.s3',
      'reachuc-media.s3.amazonaws',
      'mmmsg-vrg.reachuc',
      'uc/branding/sso/url',
      'sso-branding',
    ];
    return skipUrls.some((skipUrl) => url.includes(skipUrl));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // bypass service worker for all API requests for now.
    // later, we can define data cache policies for API requests by configuring `dataGroups` option inside `ngsw-config.json`.
    // we can create another dedicated interceptor for putting this logic if we want to go strict, but this seems like an overkill.
    request.headers.set('ngsw-bypass', '');
    //TODO: If we have several more routes that do not need inception move to a function
    if (AuthTokenInterceptor.shouldSkipAuthenticationForUrl(request.url)) {
      return next.handle(request);
    }

    const tokens = this.authService.getAccessTokens();
    if (tokens) {
      const isTokenExpired = this.jwtHelper.isTokenExpired(tokens.access_token); //Refactor

      if (isTokenExpired) {
        return this.handle401(tokens, request, next);
      } else {
        const transformedRequest = request.clone({
          url: request.url.replace('{me}', tokens.userId),
          headers: request.headers.set('Authorization', `Bearer ${tokens.access_token}`),
        });
        return next.handle(transformedRequest).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && !request.url.includes('auth/login') && error.status === 401) {
              return this.handle401(tokens, request, next);
            }
            return throwError(() => error);
          })
        );
      }
    }

    this.eventBusService.emit(new EventData(EventName.Logout, 'Issue with Authentication, please login again.'));
    return throwError(() => 'Invalid call');
  }

  private handle401(token: TokenModel, request: HttpRequest<unknown>, next: HttpHandler) {
    return this.authService.refreshToken(token).pipe(
      switchMap((newTokens: TokenModel) => {
        this.authService.setAccessTokens(newTokens);
        this.authService.updateClaims(newTokens);
        const transformedRequest = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${newTokens.access_token}`),
        });
        return next.handle(transformedRequest);
      }),
      catchError((error) => {
        //If failed again then the jwt is bad
        if ([401, 403, 400].includes(error.status)) {
          this.eventBusService.emit(
            new EventData(EventName.Logout, 'Your Authentication has expired, please login again.')
          );
        }
        return throwError(error);
      })
    );
  }
}
