<!--
 Using :host for styling this component in a dialog doesn't seem to work so 
 we have a root div for that purpose
-->
<div class="container">
  <div class="header">
    <h2 mat-dialog-title>Share a screen</h2>
    <button
      class="close-button"
      mat-icon-button
      (click)="onCloseClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <mat-dialog-content>
    <div class="sources-container">
      @for (source of sources; track source.id) {
        <div
          class="source-card"
          (click)="onSourceSelected(source.id)">
          <div class="source-card-content">
            <div class="thumbnail-container">
              <img [src]="source.thumbnailDataUrl" />
            </div>
            <div class="source-name">{{ source.name }}</div>
          </div>
        </div>
      }
    </div>
  </mat-dialog-content>
</div>
