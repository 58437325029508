import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppFeature } from '@app/core/models/config.models';
import { EventData, EventName } from '@app/core/models/event-bus.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { AppInitializerService } from '@app/core/services/app-initializer.service';
import { EventBusService } from '@app/core/services/event-bus.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ButtonType } from '@app/shared/models/dialog-data';
import { skip } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-initial-loading',
  templateUrl: './initial-loading.component.html',
  styleUrls: ['./initial-loading.component.scss'],
  imports: [MatProgressSpinnerModule, AsyncPipe, NgIf],
})
export class InitialLoadingComponent {
  private timeoutLogoutId: NodeJS.Timeout;

  constructor(
    public initializer: AppInitializerService,
    private eventBusService: EventBusService,
    private appConfigService: AppConfigService,
    private dialog: MatDialog
  ) {
    this.appConfigService.features$.pipe(skip(1)).subscribe((res) => {
      if (!res[AppFeature.UCWebAccess]) {
        this.showCUCDisabledDialog();
      }
    });
  }

  private showCUCDisabledDialog() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'ConnectUC disabled for this user account.',
        buttons: [
          {
            label: 'Logout',
            action: () => this.clearLogoutTimeout(),
            type: ButtonType.matRaisedButton,
            color: 'primary',
          },
        ],
      },
      width: '350px',
      disableClose: true,
    });
    this.timeoutLogoutId = setTimeout(() => {
      this.logout();
    }, 5000);
  }

  private clearLogoutTimeout() {
    this.logout();
    clearTimeout(this.timeoutLogoutId);
  }

  private logout() {
    this.eventBusService.emit(new EventData(EventName.Logout));
  }
}
